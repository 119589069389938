.cell-value-green {
  background-color: #a9cc9e;
}

.cell-value-red {
  background-color: #e6b8b8;
}

.selected-ticket {
  background-color: orangered;
  display: block;
  width: max-content;
  padding: 3px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  color: white;
  cursor: pointer;
}

.selected-container {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1100px;
}

.error-box {
  background-color: #e6b8b8;
  padding: 10px;
  border-radius: 7px;
  margin: 0px auto;
  color: #9e1c1c;
  font-weight: 500;
  font-size: 1.1rem;
  max-width: 1100px;
  width: 100%;
}

.ag-theme-alpine {
  max-height: 500px; /* set a maximum height for the container */
}

.selected {
  background-color: green !important;
}
