.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.login-card {
  width: 450px;
  padding: 20px 30px;
  background-color: #252525;
  border-radius: 5px;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo-container img {
  height: 50px;
}

.form-group {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input {
  height: 40px;
  border-radius: 5px;
}

.btn-primary {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: orangered;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
}

.btn-primary:hover {
  background-color: red;
}
