.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: #252525;
  width: 450px;
  height: 220px;
  border: 1px solid whitesmoke;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-footer {
  display: flex;

  justify-content: end;
}
.modal-footer button {
  padding: 10px;

  width: 45%;
  margin: 0 5px;
  border: none;
  height: 50px;
}

.modal-footer .success {
  background: green;
  color: white;
}
