.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background-color: #252525;
  transform: translateX(-90%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar .sidebar-open-btn {
  position: absolute;
  right: -15px;
  background-color: transparent;
  border: none;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 90px;
}

.sidebar-header .logo {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.sidebar-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.sidebar-toggle:hover {
  color: #ccc;
}

.sidebar-toggle:focus {
  outline: none;
}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.icon {
  background-color: #252525;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
  color: red;
}

.icon .close {
  transform: rotate(-190deg);
}

ul {
  list-style: none;
  width: 90%;
  margin: 0 auto;
}

ul li {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
}

.nav li span {
  color: white;
  font-size: 1.25rem;
}

.sidebar-logout {
  width: 90%;
  height: 40px;
  background: orangered;
  color: white;
  border: none;
  font-size: 1.25rem;
}
